
import {
    apiModelList,
    apiSynchronizat,
    apiSynchronizatStore,
    apiChangestatus,
    apiCategoryList,
    apiModelSetfactory,
    apiModelDelete,
    apiModlestlylist,
    apiSettingsGoodsCopyRemoteModleList
} from "@/api/model";
import { apiShopLists } from "@/api/shop";

import { Component, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import { debounce } from '@/utils/util'
type AsyncRequestFunction = (index: number, value: any) => Promise<string>;
// 定义回调函数的类型，它接受一个可能的错误和请求结果数组
type CallbackFunction = (error: Error | null, results: string[]) => void;
@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class ModelList extends Vue {
    $refs!: { table: any }
    /** S Data **/
    apiModelList = apiModelList;
    apiSettingsGoodsCopyRemoteModleList = apiSettingsGoodsCopyRemoteModleList

    pager: RequestPaging = new RequestPaging();
    dialogPager: RequestPaging = new RequestPaging();

    searchObj: any = {
        title: "",
        cate_id: "",
        own_sid: "",
        type_id: "",
    };
    id = null;
    categoryList: any[] = [];
    shop_lists: any[] = [];
    syncLoading: boolean = true;
    selectIds: any[] = [];
    storeShow = false;
    sid = null;

    shop_page = 1;
    shopTotal = 1;

    typeList: any[] = [];

    showModel = false
    pickObj: any = []
    keyword = ''
    modelSelectList = []
    type = 0 // 模型分配商户 0 单选 1批量
    /** E Data **/

    btachShop() {
        this.type = 1;
        this.storeShow = true;
    }

    setRowKey(row: any) {
        return row.id
    }

    modelSelectionChange(e: any) {
        this.modelSelectList = e;
    }

    updateModel(id: number) {
        this.$confirm('此操作将同步模型配置,是否继续?', '提示', {
            type: 'warning'
        }).then(() => {
            return apiSynchronizat({
                id
            })
        }).then(() => {
            this.getDialogModelList();
        })
    }

    searchKeyword() {
        this.dialogPager.page = 1;
        this.getDialogModelList();
    }

    handleSelectionChange(e: any) {
        this.pickObj = e;
    }

    getRowKey(row: any) {
        return row.id;
    }

    openModal() {
        this.$nextTick(() => {
            this.dialogPager.lists.forEach((v: any, i: number) => {
                if (v.have) {
                    this.$refs.table.toggleRowSelection(this.dialogPager.lists[i], true);
                    this.dialogPager.lists[i].isDefault = 1;
                } else {
                    this.dialogPager.lists[i].isDefault = 0;
                }
            });
        })
    }

    confirmModel() {
        if (this.pickObj.filter((v: any) => v.isDefault === 0).length === 0) return this.$message.error('请选择模型');
        const ids = this.pickObj.map((v: any) => {
            if (v.isDefault === 0) {
                return v.id
            }
        }).filter((v: any) => v !== undefined);
        apiSynchronizat({
            id: ids.join(',')
        });
        return
        this.executeRequestsBasedOnIndex(ids, apiSynchronizat, (error, results) => {
            if (error) {
                console.error('请求失败:', error.message);
            } else {
                this.getDialogModelList();
                console.log('所有请求完成:', results);
            }
        })
    }

    showModalList() {
        this.showModel = true;
    }

    // 判断多选框能否勾选，当订单为补单状态时，无法勾选
    selectable(row: any, index: number) {
        return row.have === 0 ? true : false;
    }

    /** S Methods **/

    // 获取模型弹窗列表
    getDialogModelList(): void {
        this.dialogPager.request({
            callback: apiSettingsGoodsCopyRemoteModleList,
            params: {
                page: this.dialogPager.page,
                keyword: this.keyword
            }
        }).then((res: any) => {
            if (this.$refs.table) {
                res.lists.forEach((v: any, i: number) => {
                    this.$nextTick(() => {
                        if (v.have) {
                            this.$refs.table.toggleRowSelection(res.lists[i], true);
                            this.dialogPager.lists[i].isDefault = 1;
                        } else {
                            this.dialogPager.lists[i].isDefault = 0;
                        }
                    })
                });
            }
        });
    }

    // 获取模型列表
    getModelList(): void {
        this.pager.request({
            callback: apiModelList,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 模型分类列表
    getCategoryList() {
        apiCategoryList({}).then((res) => {
            this.categoryList = res.category_list;
        });
    }

    modelToShop() {
        apiModelSetfactory({ 
            id: this.type === 1 ? this.modelSelectList.map((v:any) => v.id).join(',') : this.id, 
            own_sid: this.sid
        }).then((res) => {
            this.sid = null;
            this.storeShow = false;
            this.getModelList();
        });
    }
    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }

    // 执行基于索引的异步请求的函数
    executeRequestsBasedOnIndex<T>(
        array: T[],
        requestFunction: AsyncRequestFunction,
        callback: CallbackFunction
    ): void {
        // 创建一个空数组来收集所有的Promise
        const promises: Promise<string>[] = [];

        // 遍历数组的每个元素
        array.forEach((value, index) => {
            // 根据当前的索引和元素值创建一个Promise
            //@ts-ignore
            const promise = requestFunction({ id: value });
            promises.push(promise);
        });

        // 使用Promise.all等待所有的Promise完成
        Promise.all(promises)
            .then(results => {
                // 所有请求完成后，调用回调函数
                callback(null, results);
            })
            .catch(error => {
                // 如果有请求失败，调用回调函数并传入错误信息
                callback(error, []);
            });
    }

    // 定义一个示例异步请求函数，这里使用setTimeout模拟异步操作
    asyncRequest(index: number, value: any): Promise<string> {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log(`请求${index}完成，值为${value}`);
                resolve(`请求${index}的结果是：${value}`);
            }, Math.random() * 1000); // 随机延迟以模拟异步操作
        });
    }

    //获取商家列表
    getShopList() {
        apiShopLists({ page_no: this.shop_page, page_size: 999 }).then(
            (res: any) => {
                this.shop_lists = res.lists;
                this.shopTotal = res.count;
            }
        );
    }
    getTypeList() {
        apiModlestlylist({ page_size: 999 }).then((res) => {
            this.typeList = res.lists;
        });
    }
    turnShopPage(e: any) {
        this.shop_page = e;
        this.getShopList();
    }

    async synchronizat(): Promise<void> {
        this.syncLoading = false;
        await apiSynchronizat({});
        this.syncLoading = true;
        this.getModelList();
    }

    async synchronizatBystore(): Promise<void> {
        this.syncLoading = false;
        await apiSynchronizatStore({});
        this.syncLoading = true;
        this.getModelList();
    }

    // 修改状态
    async shopSwitchStatusEdit(status: any, id: number): Promise<void> {
        await apiChangestatus({ id, is_show: status });
        this.getModelList();
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getModelList();
    }

    // 去编辑
    toModelEdit(id: number | any) {
        this.$router.push({
            name: "model_edit",
            query: {
                id: id as any,
                mode: PageMode.EDIT,
            },
            params: {
                pager: this.pager as any,
                searchObj: this.searchObj as any,
            },
        });
    }
    handleDelete(id: number) {
        apiModelDelete({ id: id }).then((res) => {
            this.getModelList();
        });
    }
    /** E Methods **/

    /** S ods **/
    created() {
        const params: any = this.$route.params;
        if (params.pager) {
            (this.pager as any) = params.pager;
        }
        if (params.searchObj) {
            (this.searchObj as any) = params.searchObj;
        }
        this.getDialogModelList();
        this.getModelList();
        this.getCategoryList();
        this.getShopList();
        this.getTypeList();
        this.searchKeyword = debounce(this.searchKeyword, 500)
    }
}
